export const CreditRechargeAmounts = [
  {
    label: '$1,000',
    value: 1000,
  },
  {
    label: '$1,500',
    value: 1500,
  },
  {
    label: '$2,000',
    value: 2000,
  },
];

export const CreditRechargeThreshold = 500;

export const MaxCreditBalance = 2000;
