<template>
  <ElDialog
    id="add-business-credits-dialog"
    :center="true"
    :close-on-click-modal="false"
    :fullscreen="$viewport.lt.md"
    :show-close="false"
    :visible.sync="reactiveVariables.isVisible"
    :width="$viewport.lt.md ? '100vw': '600px'"
  >
    <div class="row justify-content-center gutters-2">
      <template>
        <div class="row justify-content-center align-items-center font-weight-strong font-21">
          <template v-if="reactiveVariables.creditSuccess === true">
            Processing your ACH Funds payment
          </template>
          <template v-else-if="reactiveVariables.creditSuccess === false">
            Cannot Process ACH Funds payment
          </template>
          <template v-else>
            Add Funds
          </template>
        </div>
        <div class="col-12 font-17">
          <template v-if="reactiveVariables.creditSuccess === true">
            <ElAlert
              type="success"
              show-icon
              :closable="false"
              class="p-5 my-3"
            >
              Processing your ACH Funds payment of <strong>${{ withCommas(reactiveVariables.rechargeAmount) }}</strong>, funds will be added to your account once the payment is successful.
            </ElAlert>
          </template>
          <template v-else-if="reactiveVariables.creditSuccess === false">
            <ElAlert
              type="warning"
              show-icon
              :closable="false"
              class="p-5 my-3"
            >
              <div>
                <div class="font-weight-strong">
                  ACH Funds payment failed
                </div>
                <div v-if="reactiveVariables.errorMessage">
                  {{ reactiveVariables.errorMessage }}
                </div>
              </div>
            </ElAlert>
          </template>
          <template v-else>
            <div class="text-center mb-4">
              <i class="sdicon-money-bills font-27 text-primary" />
            </div>
            <p>
              Select how much you want to transfer from your bank account.  Future charges for Showdigs services will use this balance first.
            </p>
            <p><strong>Note:</strong> It will take 2-3 days for the transfer to complete, and the balance to be available in Showdigs</p>
          </template>
        </div>
        <div
          v-if="reactiveVariables.creditSuccess === null"
          class="col-md-6 col-12"
        >
          <SdFloatLabel label="Funds to add">
            <ElSelect
              v-model="reactiveVariables.rechargeAmount"
              placeholder="Funds to add"
            >
              <ElOption
                v-for="option in CreditRechargeAmounts"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </ElSelect>
          </SdFloatLabel>
        </div>
      </template>
    </div>
    <div slot="footer">
      <div
        :class="{'justify-content-center align-items-center': reactiveVariables.creditSuccess === null}"
      >
        <el-button
          :class="$viewport.lt.md ? 'w-100 mb-2' :''"
          size="medium"
          @click="reactiveVariables.isVisible = false"
          @click.stop
        >
          {{ reactiveVariables.creditSuccess === null ? 'Cancel' : 'Close' }}
        </el-button>
        <el-button
          v-if="reactiveVariables.creditSuccess === null"
          :class="$viewport.lt.md ? 'w-100 ml-0' :''"
          :disabled="!reactiveVariables.rechargeAmount || loadingRef"
          :loading="loadingRef"
          size="medium"
          type="primary"
          @click="addCredit"
          @click.stop
        >
          Add Funds
        </el-button>
      </div>
    </div>
  </ElDialog>
</template>

<script>
import { reactive, ref, computed } from '@vue/composition-api';
import { CreditRechargeAmounts } from '@/constants/Billing';
import { withCommas } from '@/utils/NumberUtil';

export default {
  name: 'AddBusinessCreditsDialog',
  setup(props, context) {
    const reactiveVariables = reactive({
      isVisible: false,
      rechargeAmount: null,
      creditSuccess: null,
      errorMessage: null,
    });
    const businessCreditsBalance = computed(() => context.root.$store.getters['Business/businessCreditsBalance']);
    const loadingRef = ref(false);

    return {
      loadingRef,
      reactiveVariables,
      open,
      addCredit,
      withCommas,
      businessCreditsBalance,
      CreditRechargeAmounts,
    };

    async function open() {
      reactiveVariables.isVisible = true;
      reactiveVariables.rechargeAmount = null;
      reactiveVariables.creditSuccess = null;
    }

    async function addCredit() {
      loadingRef.value = true;

      try {
        const updatedBusinessCreditsBalance = await context.root.$store.dispatch('Billing/addBusinessCredits', reactiveVariables.rechargeAmount);
        context.root.$store.commit('Business/setBusinessCreditsBalance', updatedBusinessCreditsBalance);

        reactiveVariables.creditSuccess = true;
      } catch (error) {
        reactiveVariables.creditSuccess = false;
        reactiveVariables.errorMessage = error.message;
      }

      loadingRef.value = false;
    }
  },
};
</script>
