<template>
  <SdLoadingLayer
    ref="loadingLayer"
    :init="init"
  >
    <div
      slot="loaded"
      class="position-relative"
    >
      <transition
        name="fade"
        mode="out-in"
        @enter="renderStripeElements"
      >
        <div
          v-if="uiFlags.isFormVisible"
          id="ach-form"
          :key="1"
        >
          <div class="row">
            <div class="col-12">
              <div
                id="bank-element"
                ref="bankElement"
              />
            </div>
            <div class="col-6">
              <ElButton
                type="gray"
                @click="hideForm"
              >
                Cancel
              </ElButton>
            </div>
            <div class="col-6">
              <ElButton
                type="primary"
                :loading="uiFlags.isAdding"
                :disabled="!uiFlags.isAddingEnabled"
                @click="addPaymentSource"
              >
                Add
              </ElButton>
            </div>
          </div>
        </div>
        <div
          v-else-if="paymentSource"
          id="payment-method-exists"
          :key="2"
        >
          <div id="label">
            Bank account details
          </div>
          <div id="card">
            {{ paymentSource.summary }}
          </div>
          <ElButton
            v-if="editable"
            size="medium"
            class="ml-auto"
            @click="showForm"
          >
            Change
          </ElButton>
        </div>
        <div
          v-else
          id="payment-method-empty-state"
          :key="3"
          @click="showForm"
        >
          <i class="sdicon-money-bills" />
          + Add ACH bank account
        </div>
      </transition>
    </div>
  </SdLoadingLayer>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import { getACHSummaryString } from '@/utils/PaymentMethodUtil';
import { showErrorNotify } from '@/utils/NotifyUtil';

export default {
  name: 'SdACHForm',
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, context) {
    // eslint-disable-next-line no-undef
    const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
    const paymentSource = ref(null);
    const uiFlags = reactive({
      isFormVisible: false,
      isAddingEnabled: false,
      isAdding: false,
    });
    const errorMessage = ref(null);
    const bankElement = ref(null);
    const elements = ref(null);

    return {
      paymentSource,
      uiFlags,
      init,
      showForm,
      hideForm,
      addPaymentSource,
      renderStripeElements,
    };

    async function init() {
      const paymentSources = await context.root.$store.dispatch('Billing/getPaymentSources');
      paymentSource.value = paymentSources.find((source) => source.type === 'us_bank_account');

      if (paymentSource.value) {
        formatPaymentSource();
        context.emit('input', paymentSource.value);
      }
    }

    function formatPaymentSource() {
      paymentSource.value.summary = getACHSummaryString(paymentSource.value);
    }

    function showForm() {
      uiFlags.isFormVisible = true;
    }

    function renderStripeElements() {
      if (!uiFlags.isFormVisible) {
        return;
      }

      elements.value = stripe.elements({
        mode: 'setup',
        currency: 'usd',
        payment_method_types: ['us_bank_account'],
        paymentMethodCreation: 'manual',
        payment_method_options: {
          us_bank_account: {
            verification_method: 'instant',
          },
        },
      });

      bankElement.value = elements.value.create('payment');

      bankElement.value.on('change', ({ error, complete }) => {
        uiFlags.isAddingEnabled = complete;
        errorMessage.value = error ? error.message : null;
      });

      bankElement.value.mount('#bank-element');
    }

    function hideForm() {
      uiFlags.isFormVisible = false;
    }

    async function addPaymentSource() {
      if (!uiFlags.isAddingEnabled || uiFlags.isAdding) {
        return;
      }
      uiFlags.isAdding = true;
      try {
        // First submit the elements form
        const { error: submitError } = await elements.value.submit();
        if (submitError) {
          throw new Error(submitError.message || 'Error submitting form');
        }

        // Then create the payment method
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          params: {
            type: 'us_bank_account',
          },
          elements: elements.value,
        });

        if (error || !paymentMethod) {
          throw new Error(error?.message || 'Error occurred');
        }

        paymentSource.value = await context.root.$store.dispatch('Billing/addPaymentSource', {
          source: paymentMethod,
        });

        formatPaymentSource();
        context.emit('input', paymentSource.value);
        uiFlags.isFormVisible = false;
      } catch (error) {
        showErrorNotify(context, error.message);
      } finally {
        uiFlags.isAdding = false;
      }
    }
  },
};
</script>

<style lang="scss">
#ach-form {
    border: 1px solid #e6e6e6;
    border-radius: $app-border-radius;
    padding: 1.75rem 1.5rem;

    .StripeElement {
        border-radius: $app-border-radius;
        border: 1px solid transparent;
        box-shadow: none;

        &--invalid {
            border-color: theme-color(danger) !important;
        }
    }

    button {
        width: 100%;
        height: 2.5rem;
        padding: 0;
    }
}

#payment-method-exists {
    width: 100%;
    min-height: 48px;
    background: gray-color(lighter);
    border-radius: $app-border-radius;
    padding: 0.25rem 0.25rem 0.25rem 1rem;
    display: grid;
    grid-template-areas:
        "label button"
        "card button";
    align-items: center;

    > #label {
        grid-area: label;
        width: 100%;
        font-size: 11px;
        line-height: 9px;
        color: gray-color(dark);
        margin-top: 3px;
    }

    > #card {
        grid-area: card;
        display: flex;
        align-items: center;
        margin-top: -3px;

        > img {
            height: 1rem;
            margin-right: 0.5rem;
        }
    }

    > button {
        grid-area: button;
        height: 2rem;
        padding: 0 0.5rem;
        margin: 0 0.5rem;
        font-size: $--font-size-base;

        &:not(:hover) {
            background: transparent;
        }
    }
}
#payment-method-empty-state {
    border: 1px solid gray-color(light);
    border-radius: $app-border-radius;
    padding: 1rem;
    color: theme-color('primary');
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $--font-size-base;

    > i {
        font-size: $--font-size-large;
    }

    &:hover {
        border-color: $link-hover-color;
        color: $link-hover-color;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: $transition-fade;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
